@font-face{
	font-family: 'Sparkasse';
	src: url(media/fonts/SparRg.ttf) format('truetype');
	font-weight: normal;
	font-style: normal
}

@font-face{
	font-family: 'Sparkasse';
	src: url(media/fonts/SparBd.ttf) format('truetype');
	font-weight: bold;
	font-style: normal
}

body {
    font-family: Sparkasse,Helvetica,Arial,sans-serif;
    font-size: 15px;
    line-height: 150%;
    color: #000;
    background: #f0f0f0;
	min-height: 100vh;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

a {
	color: #000000;
	text-decoration: underline;
}

.relative {
	position: relative;
}

.flex-1 {
	flex: 1;
}

.mb-10 {
	margin-bottom: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-40 {
	margin-top: 40px;
}

.py-40 {
	padding-top: 40px;
	padding-bottom: 40px;
}